import {createI18n} from 'vue-i18n';
import cache from '@/utils/cache';

import zh from './lang/zh';
import en from './lang/en';
import fr from './lang/fr';
import es from './lang/es';


export const i18n = createI18n({
    fallbackLocale: 'en',
    legacy: false, // composition API
    globalInjection: true, // 全局生效$t
    locale: cache.getLang(), // 默认zh翻译
    messages: {
        en,
        zh,
        fr,
        es
    }
})

export function i18nText(val) { // 导出语言切换使得在其他js文件中也能使用多语言
    try {
        const {t} = i18n.global
        return t(val)
    } catch (error) {
        console.log(error);
    }
}
