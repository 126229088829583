import {$get} from '@/utils/request';
import apiPost from './apiPost';

export default {
    async getList(url, codeToMessage) {
        var response = await $get(url);
        if (apiPost.checkResponse(response, codeToMessage)) {
            return response.data || [];
        }
        return [];
    },

    async getData(url, codeToMessage) {
        var response = await $get(url);
        if (apiPost.checkResponse(response, codeToMessage)) {
            return response.data;
        }
        return null;
    },
}

