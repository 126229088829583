import apiPost from './apiPost';
import apiGet from './apiGet';
import {i18nText} from '@/locals/index';

// 首页示例单号接口
export const apiSampleTrackingNo = async () => {
    const URL = "/sampletrackingno";

    return await apiGet.getData(URL);
};

export const submitSuggest = async (data) => {
    const URL = "/use-opinion";

    return await apiPost.getSuccess(URL, data);
};

export const logout = async (data) => {
    const URL = "/logout";

    return await apiPost.getSuccess(URL, data);
};

export const changePwd = async (data) => {
    const URL = "/modify-pwd";

    let codeToMessage = new Map();
    codeToMessage.set(110, i18nText('message.request_portal_110_password_error'));
    codeToMessage.set(112, i18nText('message.request_portal_112_password_format_wrong'));

    return await apiPost.getSuccess(URL, data, codeToMessage);
};

export const googleUserinfo = async (data) => {
    const URL = "/googleUserinfo" ;

    return await apiPost.getData(URL, data);
};
