import axios from "axios";
import cache from '@/utils/cache';
import { warningMessage } from '@/utils/messageUtils';
import { errorMsg } from '@/utils/messageUtils';
import { getBaseUrl, routerToUrl, isCN } from '@/utils/tool';
import { i18nText } from '@/locals/index';
import webConfig from '@/config/wenConfig';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.withCredentials = true;
let baseURL = getBaseUrl(); // switch (process.env.NODE_ENV) {
//     case 'production': baseURL = 'http://172.30.10.12:7060/portal'
//         break;
//     case 'test': baseURL = 'http://172.30.10.12:7060/portal'
//         break;
// }

let config = {
  baseURL: baseURL,
  timeout: 100000
};
const instance = axios.create(config);
instance.interceptors.request.use(function (config) {
  // config.headers['X-Language'] = webConfig.getCurrentLang().replace('zh', 'cn');
  config.headers['lang'] = webConfig.getCurrentLang().replace('zh', 'cn'); // 只有中文才需要替换
  // config.headers['_fc'] = 1;
  // //语言环境
  // config.headers['Local'] = 'zh';
  // config.url = `/sys${config.url}`;
  // 防止浏览器接口缓存

  let tm = new Date().getTime();
  tm = parseInt(tm / (60 * 60 * 1000)) * 60 * 60 * 1000;

  if (config.url.indexOf('?tm=') === -1) {
    if (config.url.indexOf('?') === -1) {
      config.url = config.url + '?tm=' + tm;
    } else {
      config.url = config.url + '&tm=' + tm;
    }
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
}); // Add a response interceptor

instance.interceptors.response.use(function (response) {
  // 请求返回
  if (undefined == response.data) {
    return response;
  } else if (response.data.code == 401) {
    webConfig.saveCachedUrl();
    cache.logout();
    routerToUrl('/login');
    return null;
  }

  if (response.headers['content-disposition'] || response.headers['Content-Disposition']) {
    // blob 才返回
    return response;
  }

  return response.data;
}, function (error) {
  // 网络错误
  console.log(error); //特例处理-查询的时候 只显示一次错误提示

  if (error.config && error.config.url.indexOf('/trackings?') == 0) {
    return {
      code: -999
    };
  } else {
    warningMessage(i18nText('message.network_error'));
  } // return Promise.reject(error);


  return null;
});
export const $get = async (url, params) => {
  let response = await instance.get(url, {
    params
  });
  return response;
};
export const $post = async (url, params, config) => {
  let response = await instance.post(url, params, config);
  return response;
}; // 常规验证(401、500)

export const $normalCheck = async response => {
  const {
    t
  } = useI18n(); // 多语言

  switch (response.code) {
    case 200:
      return true;

    case 401:
      // 页面跳转到登录
      return false;

    case 500:
      errorMsg(i18nText('message.error_105'));
      return false;

    default:
      errorMsg(response.message);
      return false;
  }
}; // 常规验证(401、500)+特定Code验证

export const $codeCheck = async (response, codeToMessage) => {
  if (response.code == 200) {
    return true;
  }

  return false;
};