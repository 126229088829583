import {$post} from '@/utils/request';
import {warningMessage} from '@/utils/messageUtils';

export default {
    // 校验服务器返回状态（code=200 返回true, 否则返回false, 传入错误码对应错误，否则原样显示错误）
    checkResponse(response, codeToMessage) {
        if (null == response) {
            return false
        }

        if (response.code === 200) {
            return true;
        } else if (codeToMessage && codeToMessage.has(response.code)) {
            warningMessage(codeToMessage.get(response.code));
        } else {
            warningMessage(response.message);
        }

        return false;
    },
    async getData(url, params, codeToMessage) {
        var response = await $post(url, params);
        if (this.checkResponse(response, codeToMessage)) {
            return response.data;
        }
        return null;
    },
    async getResponse(url, params) {
        return await $post(url, params);
    },
    // 获取列表数据
    async getList(url, params, codeToMessage) {
        var response = await $post(url, params);
        if (this.checkResponse(response, codeToMessage)) {
            return response.data || [];
        }
        return [];
    },

    async getResponseByBlob(url, params) {
        return await $post(url, params, {responseType: 'blob'});
    },

    // 执行成功返回true,否则false
    async getSuccess(url, params, codeToMessage) {
        var response = await $post(url, params);
        if (this.checkResponse(response, codeToMessage)) {
            return true;
        }
        return false;
    },

    async save(url, params, codeToMessage) {
        return this.getSuccess(url,params,codeToMessage);
    },

    async deleteById(url, id, codeToMessage) {
        return this.getSuccess(url,{id},codeToMessage);
    }
}

