export default {
    message: {
        'title': '全球运单号跟踪',
        'search': 'Ai Track',
        'carrier': '自动识别运输商',
        'carrier_name': '运输商',
        'carrier_description': '系统会自动检测跟踪号码并检索相应的运输商',
        'auto_check': '自动检测',
        'clear_all': '删除全部',
        'select_transport_title': '选择运输商',
        'all_carrier': '全球各大运输商',
        'all_airline': '全部航空公司',
        'common_carriers': '常用运输商',
        'carrier_page_title': '跟踪',

        'not_found_order_no': '未查询到任何货件，请检查快递单号与运输商是否有误',
        'order_no_has_shipment': '暂无轨迹，查看航班信息',

        'network_error': '网络请求出错',
        'order_no_maybe_carriers': '运单号可能是以下运输商',
        'your_order_no_exists_many_carriers': '你的单号存在多种可能的运输商',
        'tracking_result_title': '追踪结果',
        'air_tracking_result_title': '航班信息',

        'copy_link': '复制链接',
        'copy_detail': '复制详情',

        // menu
        'menu_tracking_no_search': '运单查询',
        'menu_history': '历史关注运单',
        'menu_log': '更新日志',

        // /Result tab title
        'all': '全部',
        'unknow': '未知',

        'tracking_list_empty': '没有符合条件的记录',
        'air_list_empty': '没有航班数据',

        "email_code_send_success": '验证码已发送',

        // /运输单号校验
        'title_input_order_no': '请输入运货单号(7~50位)',
        'tracking_no_duplicate': '单号重复',
        'tracking_no_hint': '输入或黏贴运单号，最多允许提交10个单号',

        'copy_success': '复制成功',

        'normalCarriers': '物流运输商',
        'airCarriers': '航空运输商',
        'try_title': 'Try：',
        'parcel_unit': '件',

        'air_no': '航班',
        'air_start_end_station': '出发站/到达站',
        'air_maybe_start_time': '预离时间',
        'air_maybe_end_time': '预抵时间',
        'air_start_time': '起飞时间',
        'air_end_time': '抵达时间',
        'weight_unit': '重量/件数',

        // login
        'login_title': '登录',
        'login_register': '注册账号 >',
        'login_button': '登录',
        'login_forget_pwd': '忘记密码',
        'login_password': '密码',
        'login_email': '邮箱',
        'remember_me': '记住密码',

        // 忘记密码
        'forgot_pwd_title': '找回密码',
        'forgot_pwd_code': '验证码',
        'forgot_pwd_newPwd': '新密码',
        'forgot_pwd_confirmPwd': '确认密码',
        'forgot_pwd_ok': '确定',
        'forgot_pwd_login': '返回登录 >',
        'forgot_pwd_send_code': '发送验证码',
        'forgot_pwd_send_second': '秒后重发',

        // 修改密码
        'change_pwd_title': '修改密码',
        'change_pwd_old_pwd': '原密码',
        'change_pwd_old_pwd_empty': '原密码不能为空',
        'change_pwd_success': '密码修改成功',
        'change_pwd_button_ok': '确定',

        // 注册用户
        'register_title': '注册账号',
        'register_name': '昵称',
        'register_ok': '立即注册',
        'password_format_wrong': '密码长度8-16位，且至少含数字和字母，不能包含空格',

        'user_email_empty': '邮箱不能为空',
        'user_email_validate': '邮箱格式不正确',
        'user_name_empty': '昵称不能为空',
        'user_email_code_empty': '验证码不能为空',
        'user_new_pwd_empty': '密码不能为空',
        'user_confirm_pwd_empty': '确认密码不能为空',
        'user_pwd2_empty': '两次密码输入不一致',

        'regist_login': '注册/登录',
        'back_home': '返回首页',
        'registration_agreement': '注册即表示同意《AiTrack注册协议》',
        'synchronization_time': '同步时间：',

        'register_success': '注册成功',
        'reset_pwd_success': '密码重置成功',

        // 日期组件
        'date_dialog_today': '今天',
        'date_dialog_week': '最近一周',
        'date_dialog_month': '最近一月',

        // 请求code错误
        "request_101_params_error": '请求参数错误',
        "request_103_page_params_error": '分页查询参数不正确',
        "request_104_no_permission": '访问权限不足',

        "request_portal_102_login_name_exists": '昵称被占用',
        "request_portal_105_email_exists": '邮箱被占用',
        "request_portal_106_code_exists": '验证码错误',
        'request_portal_107_login_fail': '邮箱或密码错误',
        'request_portal_108_email_not_exists': '邮箱未注册',
        'request_portal_109_account_disabled': '账号被停用',
        'request_portal_110_password_error': '原密码不正确',
        'request_portal_111_email_exists': '该邮箱账号已存在，请直接登录',
        'request_portal_112_password_format_wrong': '密码长度8-16位，且至少含数字和字母，不能包含空格',

        // 请求code错误-结束

        'keyword_search_carriers': '关键字搜索运输商',

        'user_logout': '退出登录',

        'table_no': '序号',
        'table_operate': '操作',
        'table_sort_asc': '',
        'table_sort_desc': '',
        'table_page_total': '共',
        'table_page_row': '条',
        'table_no_select': '未选中任何行',
        'table_delete': '删除',
        'table_handle_tracking': '手动追踪',
        'table_handle_stop': '终止追踪',
        'table_delete_rows': '确认要删除吗？',

        // /按钮文字
        'button_confirm_text': '确认',
        'button_ok_text': '确定',
        'button_cancel_text': '取消',

        // /页面-历史运单表格列名

        'history_table_tracking_no': '运单号',
        // 'history_table_carriers': '运输商',
        'history_table_package_status': '包裹状态',
        'history_table_event': '事件',
        'history_table_event_time': '事件时间',
        'history_table_shipping_update_time': '数据更新时间',
        'history_table_update_time': '最新查询时间',
        'create_time': '创建时间',
        'history_input_tracking_no_search': '输入运单号进行查询',

        // 页面-更新日志
        'log_page_title': '支持100+跨境物流运输商',
        'log_page_description': '我们已支持USPS、ROYALMAIL、DHL、GLS和其他运输商物流查询，正在简化整合流程，继续拓展更多运输商建立合作关系',

        // 提示
        'tooltip_click_copy': '点击复制',

        // 首页运输商查询
        'home_page_input_search_carrier': '运输商查询',

        // /用户运单查询-批量导入弹框
        'user_search_page_dialog_import_batch': '批量导入',
        'user_search_page_dialog_file_upload': '点击上传文件',
        'user_search_page_dialog_file_format_incorrect': '导入文件格式不正确！',
        'user_search_page_dialog_step1_click': '1.点击',
        'user_search_page_dialog_step1_download_template': ' 下载模板 ',
        'user_search_page_dialog_step1_end': '请填写导入清单 (运输商code切换sheet后查看) 。',
        'user_search_page_dialog_step2': '2.重复运单会一并追踪，追踪后轨迹与原始运单覆盖，运单最多一次导入100条。',
        'user_search_page_dialog_step3': '3.文件导入后，运单不会立即更新。请至历史关注运单中查看追踪进度。',
        'user_search_page_dialog_select_file': '请先选择需要上传的文件',
        'user_search_page_dialog_upload_success': '文件已成功上传,您可以在历史关注运单中查看追踪进度！',
        'user_search_page_dialog_upload_fail': '导入失败',

        'user_search_page_dialog_more_then_100': '运单最多一次导入100条',

        'query_fail_input_verification': '请输入验证信息',

        // /运单查询页（查询结果页，用户查询页）
        'tracking_page_input_post_code': '请输入目的地邮政编码',
        'tracking_page_select_date': '请选择包裹收件日期',
        'tracking_page_input_phone': '请输入收件人手机号',
        'tracking_page_input_dest': '请输入目的地',

        // /提交建议
        'connect_us_title': '联系我们',
        'connect_us_samll_title': '选择您给aitrack提的建议：',
        'connect_us_input_title': '请输入标题名称',
        'connect_us_title_name': '标题名称',
        'connect_us_title_name_length_2': '标题名称至少包含2个字符',
        'connect_us_input_suggest': '请输入您的建议',
        'connect_us_your_suggest': '您的建议',
        'connect_us_your_suggest_length_10': '您的建议至少包含10个字符',
        'connect_us_your_email': '您的邮箱',
        'connect_us_your_phone': '您的手机号',
        'connect_us_your_submit_suggest': '提交建议',

        'connect_us_suggest1': '我想建议aitrack开发一个新的功能',
        'connect_us_suggest2': '我希望aitrack添加新的运输商',
        'connect_us_suggest3': '我想反馈一个bug',
        'connect_us_suggest4': '我想与aitrack进行商务合作',
        'connect_us_suggest5': '其他',

        'research_btn': '重新查询',

        // 批量导入成功结果展示
        'import_excel_result_upload_success': '文件已成功上传',
        'import_excel_result_success_count': '成功运单数：',
        'import_excel_result_fail_count': '失败运单数：',
        'import_excel_result_success_rewrite': ' 条已存在并覆盖',
        'import_excel_result_error_format': ' (格式错误)',
        'import_excel_result_view_fail_detail': '查看失败详情',
        'import_excel_result_success_description': '你可以在历史关注运单中查看追踪进度！',
        'import_excel_result_table_column_row_number': '表格行数',
        'import_excel_result_table_column_fail_tracking_no': '失败运单号',
        'import_excel_result_table_column_fail_reason': '失败原因',

        // Excel导入错误提示
        'import_excel_result_validate_tracking_no': '运单号必填',
        'import_excel_result_validate_carrier_code': '运输商code错误',
        'import_excel_result_validate_post_code': '缺少目的地邮政编码',
        'import_excel_result_validate_date': '缺少收货日期',
        'import_excel_result_validate_phone': '缺少收货人手机号',
        'import_excel_result_validate_dest': '缺少目的地',

        'import_excel_ok': '收到，对您给予的帮助和支持，我们深表感谢！',

        // /2022-04-08 后面追加新的翻译
        // /全部
        'event_names_all': "全部",
        'event_names_all_des': "",

        // /未知
        'event_names_unknown': "未知",
        'event_names_unknown_des': "",

        // /未查询到
        'event_names_not_found': "未查询到",
        'event_names_not_found_des': "包裹查询不到跟踪信息。",

        // /运输途中
        'event_names_in_transit': "运输途中",
        'event_names_in_transit_des': "运输商已接受或从发货人处取走包裹。货物已在途中。",

        // /到达待取
        'event_names_arrival_for_pickup': "到达待取",
        'event_names_arrival_for_pickup_des': "运输商将包裹送到了指定的取件点。",

        // /投递失败
        'event_names_delivery_failed': "投递失败",
        'event_names_delivery_failed_des': "运输商尝试送货但是失败。通常运输商会通知你并且尝试再次送货。",

        // /成功签收
        'event_names_signed_successfully': "成功签收",
        'event_names_signed_successfully_des': "包裹被成功签收。",

        // /可能异常
        'event_names_possible_exceptions': "可能异常",
        'event_names_possible_exceptions_des': "海关扣留、未交付、退回发件人的包裹，或任何其他运输例外情况。",

        // /运输过久
        'event_names_shipping_too_long': "运输过久",
        'event_names_shipping_too_long_des': "运输商在很长的时间里没有提供包裹的物流信息。",

        // /2022-04-11 后面追加新的翻译
        'utc_0': '世界时间 UTC+0',
        'utc_1': '东一区 UTC+1',
        'utc_2': '东二区 UTC+2',
        'utc_3': '东三区 UTC+3',
        'utc_4': '东四区 UTC+4',
        'utc_5': '东五区 UTC+5',
        'utc_5_5': '东五区 UTC+5.5',
        'utc_6': '东六区 UTC+6',
        'utc_7': '东七区 UTC+7',
        'utc_8': '东八区 UTC+8',
        'utc_9': '东九区 UTC+9',
        'utc_10': '东十区 UTC+10',
        'utc_11': '东十一区 UTC+11',
        'utc_12': '东十二区 UTC+12',
        'utc_13': '东十二区 UTC+13',
        'utc_14': '东十二区 UTC+14',
        'utc_-1': '西一区 UTC-1',
        'utc_-2': '西二区 UTC-2',
        'utc_-3': '西三区 UTC-3',
        'utc_-4': '西四区 UTC-4',
        'utc_-5': '西五区 UTC-5',
        'utc_-6': '西六区 UTC-6',
        'utc_-7': '西七区 UTC-7',
        'utc_-8': '西八区 UTC-8',
        'utc_-9': '西九区 UTC-9',
        'utc_-10': '西十区 UTC-10',
        'utc_-11': '西十一区 UTC-11',
        'utc_-12': '西十二区 UTC-12',
        'tracking_time_zone': '追踪所在时区显示',

        // /2022-04-13
        // /默认标题
        'web_title': 'Aitrack-全球运单号跟踪',
        'web_title_carrier': 'Aitrack-全球各大运输商',
        'web_title_log': 'Aitrack-更新日志',
        'web_title_user_history': 'Aitrack-历史关注运单',
        'web_title_automatically': 'Aitrack-登录',
        'web_title_carrier_detail': 'Aitrack-全球运单号跟踪',

        // /2022-04-21
        'handle_track_running': "正在自动追踪，请待完成后重试",
        'google_login': "Google",
        'other_login_title': '其他快捷登录',

        // /2022-05-07
        'web_title_register': 'Aitrack-注册账号',
        'web_title_found_pwd': 'Aitrack-找回密码',
        'web_title_guest_track': 'Aitrack-运单追踪',
        'web_title_user_track': 'Aitrack-运单查询',

        // /2022-05-09
        'web_title_description': 'Aitrack是一家集全球物流快递包裹为一体的查询平台，支持USPS，Fedex,DHL,UPS,EMS,GLS等运输商跟踪。',
        'web_title_carrier_description': 'Aitrack是一家集全球物流快递包裹为一体的查询平台，支持USPS，Fedex,DHL,UPS,EMS,GLS等运输商跟踪。',
        'web_title_log_description': 'Aitrack已支持USPS，Fedex,DHL,UPS,EMS,GLS等运输商跟踪，全球合作运输商持续新增。',
        'web_title_user_history_description': 'Aitrack帮您随时随地掌握包裹动态。',
        'web_title_automatically_description': '欢迎加入Aitrack!我们致力为全球用户提供国际物流快递包裹一站式查询服。',
        'web_title_carrier_detail_description': 'Aitrack帮您查询包裹动态。',
        'web_title_register_description': '',
        'web_title_found_pwd_description': '',
        'web_title_guest_track_description': '',
        'web_title_user_track_description': '',

        // /2022-06-09
        'user_manager_title': '账号管理',
        'other_type_login_title': '第三方账号绑定',
        'user_un_bind_title': '未绑定',
        'user_bound_title': '已绑定',
        'user_remove_bind_title': '解除绑定',
        'user_info_validate_title': '信息验证',
        'user_info_phone': '手机号',
        'user_info_change_phone': '更换手机号',
        'user_info_bind_phone': '绑定手机号',
        'user_info_change_email': '变更绑定邮箱',
        'btn_save': '保存',
        'send_email_code_more': '请不要频繁发送验证码',

        // /2022-06-10
        'change_email_old': '旧邮箱验证',
        'change_email_new': '新邮箱验证',
        'change_email_btn_next': '下一步',

        'request_modify-email_106_error': '旧邮箱验证码不正确',
        'request_modify-email_107_error': '新邮箱验证码不正确',
        'request_modify-email_same_email_error': '新旧邮箱不能相同',

        'bind_phone_title': '手机号验证',
        'un_validate_phone_title': '未绑定',
        'phone_is_empty_title': '手机号不能为空',
        'validate_phone_error': '验证码不正确',
        'please_send_code_email': '请访问邮箱获取验证码',
        'validate_card': '验证邮箱',
        'interface_security_title': '订阅接口密钥',
        'reset_security_key': '更换密钥',
        'security_key': '密钥',

        'reset_security_confirm': '如果更换密钥，之前的密钥将无效，您确定要更换么？',
        'btn_search': '查询',
        'your_tracking_no': '您的运单',
        'vip_tracking_page_url': '专属跟踪页面URL',
        'vip_tracking_page_url_description': '复制跟踪页面链接并将其添加到您商店的网站',
        'h5_not_found_title': '很抱歉，未找到该单号信息',
        'h5_not_found_content': '请检查运单信息是否正确，无法追踪未发货的运单',

        // 操作成功
        'save_success': '操作成功',

        // /2022-06-23
        'user_match_rule_title': '自定义事件',
        'user_match_rule_keywords_title': '输入事件名称',
        'btn_event_management': '事件管理',
        // 'btn_new_match_rule': '新增匹配规则',
        'condition_rule_match_type': '规则类型',
        'condition_trajectory_rule': '轨迹规则',

        // /2022-06-24
        'history_exist_trackingNo_carrierCode': '此运单运输商信息已存在，无法变更',

        // /2022-06-27
        'event_match_page_select_event': '选择事件',
        'event_match_page_event_manage': '事件管理',
        'event_match_page_info': '(注：可以跳过状态直接搜索事件)',
        'event_match_page_view_all_event': '查看所有事件',
        'event_match_page_view_add_event': '新增自定义事件',
        'event_match_page_view_edit_event': '编辑自定义事件',

        'event_match_page_view_state_lv1': '一级状态',
        'event_match_page_view_state_lv2': '二级状态',
        'event_match_page_view_event': '事件',

        'event_match_page_view_state_lv1_name': '一级状态名称',
        'event_match_page_view_state_lv2_name': '二级状态名称',
        'event_match_page_view_event_name': '事件名称',
        'event_match_page_view_status_title': '启用状态',
        'event_match_page_view_begin_date_title': '有效开始日期',
        'event_match_page_view_end_date_title': '有效结束日期',

        'event_match_page_add_matching_url_title': '新增匹配规则',
        'event_match_page_edit_matching_url_title': '编辑匹配规则',

        'remark': '备注',
        'full_matching_rule': '全量匹配',
        'reg_matching_rule': '正则表达式匹配',
        'matching_rule_title': '规则类型',

        'matching_rule_error_103': '更新失败',
        'matching_rule_error_104': '匹配规则已存在',
        'matching_rule_error_105': '匹配公式无法使用',

        'error_105': '网络异常，请稍后再试',
        'error_996': '查询失败，可联系客服帮忙查看！',

        'event_match_page_view_begin_date_empty_title': '请选择有效开始日期',
        'event_match_page_view_end_date_empty_title': '请选择有效结束日期',
        'event_match_page_view_begin_end_date_error': '有效结束日期不能早于有效开始日期',

        'event_match_page_test_matching': '匹配测试',
        'event_match_page_test_matching_title': '正则表达式规则测试',
        'event_match_page_test_trajectory_title': '轨迹信息',
        'event_match_page_test_input_trajectory_title': '请输入轨迹信息',
        'event_match_page_test_fail': '轨迹信息匹配公式失败',

        'event_match_help': "学习网站: <a href='https://www.runoob.com/regexp/regexp-syntax.html' target='_blank'>https://www.runoob.com/regexp/regexp-syntax.html</a><br/>简单示例：<br/>^ABC 匹配以ABC开头的信息<br/>^ABC\$ 匹配以ABC结尾的信息<br/>^AB C\$ 匹配以AB开头，C结尾的信息<br/>AB.A0,A30 匹配以AB开头，0到30个任意字符结尾<br/>.*AB.* 匹配以任意字符开头、包含AB、任意字符结尾的信息<br/>",

        // /2022-07-05
        'event_info_save_102': '事件名称已存在',

        // /2022-07-06
        'carrier_full_name': '运输商名称',
        'table_edit': '编辑',
        'status': '状态',
        'status_open': '启用',
        'status_close': '禁用',

        'event_match_page_test_success': '轨迹信息匹配公式成功',

        // /2022-07-07
        'user_page_timezone_title': '追踪轨迹时间显示',
        'user_page_timezone_item_carrier_timezone': '按运输商所在时区显示',
        'user_page_timezone_item_user_timeone': '按您所在时区显示',

        // /2022-07-11
        'page_size': '每页条数',
        'page_jump': '跳转到',

        // /2022-07-20
        'user_bi_analysis_page_title': '妥投分析',
        'all_carrier_title': '全部运输商',
        'all_country_title': '全部国家',

        'day_title': '日',
        'week_title': '周',
        'month_title': '月',

        'day': '天', // 单数
        'days': '天', // 复数

        'quantile_title': '分位',
        'destination_country_title': '目的地国家',
        'query_time_range_title': '查询时间范围',
        'tt_days_title': '妥投天数阶梯分段',
        'ntt_days_title': '未妥投天数阶梯分段',
        'temp_kpi_title': '临时KPI标准',

        'kpi_manage_title': '标准KPI管理',
        'otd_analysis_title': 'OTD分析',
        'otd_tt_analysis_title': '已妥投分析',
        'otd_utt_analysis_title': '未妥投分析',

        'standard_title': '达标',
        'un_standard_title': '未达标',
        'proper_investment_title': '妥投情况',
        'delivery_success_rate_title': '妥投率',
        'proportion_of_investment_days_title': '妥投天数占比',
        'details_of_completed_investment_title': '已妥投明细',
        'failure_rate_title': '未妥投率',
        'details_of_incomplete_investment_title': '未妥投明细',

        'proportion_of_up_to_standard_title': '达标占比',
        'time_for_reaching_the_standard_title': '达标用时',
        'kpi_all_title': '全部KPI',
        'kpi_standard_title': '标准KPI',
        'kpi_temporary_title': '临时KPI',

        'please_select_query_time_range_title': '请选择查询时间范围',
        'query_time_range_end_less_start_title': '查询时间范围结束时间不能小于开始时间',
        'query_days_15_title': '查询时间范围不能超过15天',
        'query_weeks_15_title': '查询时间范围不能超过15周',
        'query_months_15_title': '查询时间范围不能超过15个月',

        'temporary_kpi_rate_0_100_title': '临时KPI标准分位范围：0-100',

        'unsuccessful_delivery_orders_title': '未妥投运单数',
        'unsuccessful_bid_rate_title': '未妥投率',

        'successful_delivery_orders_title': '已妥投运单数',
        'successful_bid_rate_title': '妥投率',

        'country_title': '国家',
        'kpi_type_title': 'KPI类型',
        'avg_score_title': '平均分位',
        'avg_time_title': '平均用时',
        'delay_title': '延迟',
        'delay_more_9_days_title': '延迟10及以上天',

        'total_votes_title': '总票数',
        'delivered_votes_title': '已送达票数',
        'un_delivered_votes_title': '未送达票数',
        'delay_votes_title': '延迟票数',
        'within_title': '内', // 1天内
        'more_than_title': '以上', // 1天以上
        'shipped_orders_title': '已妥投运单数',
        'proportion_title': '占比',

        'total_number_of_waybills_including_undelivered_waybills': '总运单数 (含未送达运单)',
        'total_title': '总计',
        'total_tracking_no': '总运单数',
        'unshipped_orders_title': '未妥投运单数',

        // /标准KPI管理
        'kpi_standard_quantile_title': 'KPI标准分位',
        'quantile_compliance_days': '分位达标天数',
        'dialog_standard_KPI_management_title': '标准KPI管理',
        'add_kpi': '新增KPI',
        'edit_kpi': '编辑KPI',
        'import_103': '请下载最新的模板',

        'please_input_kpi_quantile_title': '请输入KPI分位',
        'kpi_quantile_0_100_title': 'KPI分位范围0-100',
        'please_select_carrier_title': '请选择运输商',
        'please_select_quantile_day_title': '请选择分位达标天数',

        'select_country_title': '选择国家',
        'please_select_country_title': '请选择国家',
        'kpi_quantile_title': 'KPI分位',
        'quantile_day_title': '分位达标天数',
        'time_range_title': '时间范围',

        'waybill_collection_time': '运单收件时间',
        'country_origin': '始发国',
        'destination_country': '目的国',
        'origin_title': '始发地',
        'destination_title': '目的地',

        'airline_title': '航空公司',
        'airline_master_number': '航空主单号',
        'tracking_number_information': '运单信息',
        'view_more_tracking_information': '显示更多轨迹',

        'no_tracking_data': '暂无物流信息',

        // /2022-07-27
        'please_select_1_item': '请至少选择一项',
        'recipient_phone': '收件人手机号',
        'recipient_email': '收件人邮箱',
        'export_otd_title': '导出延迟及未送达运单',
        'select_export_otd_kpi_type_title': '选择需要导出的KPI类型',
        'export_delivered': '导出已妥投运单',
        'export_un_delivered': '导出未妥投运单',

        // /2022-08-01
        'page_user_title': '账号信息设置',
        'page_user_member_title': '成员账号管理',
        'page_user_subscription_title': '我的额度管理',
        'amount_title': '账号',

        'tooltip_title': '提示',
        'delete_confirm_title': '确认要删除',
        'add_member': '新增成员',
        'edit_member': '编辑成员',
        'setting_pwd': '密码设置',
        'auto_pwd': '自动生成密码',
        'define_pwd': '自定义密码',
        'tooltip_pwd': '*用户创建成功后默认发送账号密码至邮箱，登录后可修改密码',

        'ddl_clear_all': '清除',

        'select_tt_days_title': '请选择妥投天数阶梯分段',
        'select_ntt_days_title': '请选择未妥投天数阶梯分段',
        '8_20_length': '8-20位字符',
        'request_portal_115_not_activated_wrong': '该账号未启用,请联系管理员',

        'kpi_exists_override': '该标准KPI已存在，是否要覆盖它？',

        // /2022-08-05
        'recharge': '购买',
        'subscription': '消耗',
        'free': '赠送',
        'deduction': '扣减',

        'date': '日期',
        'quota': '额度',
        'type': '类型',

        'quota_validity_period': '有效期',
        'remaining_query_quota': '剩余订阅额度',
        'please_choose': '请选择',
        'please_input': '请输入',
        'not_empty': '不能为空',

        'email': '邮箱',
        'save_successful': '保存成功',
        'delete_successful': '删除成功',

        'clear': '清空',

        // /2022-08-11
        'user_notify_center_title': '通知中心',
        'page_user_notify_task_title': '通知任务',
        'page_user_notify_template_title': '通知模板管理',
        'add': '新增',

        'task_name': '任务名称',
        'trigger_task_event': '触发任务事件',
        'notify_type': '通知类型',
        'mail': '邮件',
        'sms': '短信',
        'notify_event_type': '通知事件类型',
        'update_time': '修改时间',
        'add_task': '新增通知任务',
        'edit_task': '编辑通知任务',

        'task_tooltip': '注：可选择需要发送通知的运输商，不填默认所有运输商运单均通知',
        'template': '模板',
        'preview': '预览',
        'template_name': '模板名称',

        'email_template': '邮件模板',
        'add_email_template': '新增邮件模板',
        'edit_email_template': '编辑邮件模板',

        'sms_template': '短信模板',
        'add_sms_template': '新增短信模板',
        'edit_sms_template': '编辑短信模板',

        'template_desc': '模板描述',

        'view_url': '快速查看运单详情链接',

        'template_content': '模板内容',

        'view': '查看',
        'edit': '编辑',

        // 2022-08-15
        'send_test_sms': '发送测试短信',
        'send_test_email': '发送测试邮件',
        'send_test_sms_target': '发送测试短信到',
        'send_test_email_target': '发送测试邮件到',

        'send_test_sms_tooltip': '成功测试短信会扣减短信额度',
        'send_test_email_tooltip': '成功测试邮件会扣减邮件额度',
        'send_successful': '发送成功',

        'view_sms_template': '查看测试短信',
        'view_email_template': '查看测试邮件',

        'email_subject': '主题',

        'api_trackings_109': '抱歉，无法完成查询，您的订阅额度不足',
        'api_trackings_110': '抱歉，无法完成查询，您的订阅额度已失效',
        'recommend': '推荐',
        'customize': '自定义',
        'exists_template_name': '模板名称已存在',
        'exists_task_name': '任务名称已存在',
        'join_task_template': '存在关联任务，删除失败',

        // /2022-08-18
        'template_language': '模板语言',
        'EN': 'EN',
        'CN': 'CN',
        'ALL': 'ALL',

        'user_page_callback_url': '订阅回调URL',
        'user_page_callback_url_tip': '请根据接口文档开发相应的回调接口，在下方录入回调地址后，如果您的运单轨迹发生变化，AI TRACk将主动发起回调，将最新的轨迹数据推送到您的回调接口',
        'callback_url': '回调URL',
        'callback_url_error': '回调URL格式不正确',

        'balance_detail': '余额明细',
        'subscribe_detail': '订阅额度明细',

        'balance_yuan': '可用账户余额',
        'operate_time': '操作时间',

        'money_yuan': '金额',

        'balance_recharge': '充值',
        'sms_pay': '短信消费',
        'change_add': '赠送',
        'change_reduce': '扣减',

        // /2022-08-25
        'page_sms_service_title': '短信服务',
        'page_sms_service_description': '可为您提供定制化的短信通知，通知收件人去取件或异常处理等，快速提升客户服务质量',
        'page_sms_service_note': '提醒：由于短信价格因国家、地区和电信运营商定价而定，可能会有变更，不另行通知。',
        'telecom_operators': '运营商',
        'sms_price': '目前每条短信价格（人民币：元）',
        'sms_price_download': '下载短信定价表',
        'sms_template_note': '注意：请注意短信模版内容，模版内容过长可能会收取多份费用。英文字母和数字每条短信限制160字符，含非英文字母或特殊字符的短信内容将降级为每条短信70字符。超出限制后，内容可以正常发送，但会多收取短信费用。具体收费可查看短信发送记录页面。',

        'page_shop_title': '店铺管理',
        'page_shop_tooltip': '最多可以添加10个店铺',
        'add_shop': '新增店铺',
        'edit_shop': '编辑店铺',
        'shop_name': '店铺名称',
        'shop_type': '店铺类型',
        'shop_url': '店铺地址',
        'shop_status': '店铺状态',

        'shop_delete_title': '删除后将清除该店铺中的所有运单，且无法恢复，确认要删除店铺吗？',
        'shop_disable': '停用后将无法新增运单，且已有运单不再同步轨迹，确认要停用吗？',
        'import_data': '导入数据',
        'synchronous_data': '同步数据',
        'synchronous_days_range': '导入运单的时间范围',

        'shop_save_104': '参数不合法',
        'shop_save_105': '店铺数量超限',
        'shop_save_106': '同步时间超出限制',
        'shop_save_107': '店铺名重复',
        'synchronous_data_tooltip': '时间范围内运单将导入，之后新增的运单将自动持续同步，首次同步可能会持续几分钟至几天时间。',

        'page_user_sms_detail_title': '短信费用明细',
        'send_time': '发送时间',
        'send_status': '发送状态',
        'successful': '成功',
        'failure': '失败',
        'send_country': '发送国家',
        'received_phone': '接收手机号',
        'count_sms': '短信计费条数',
        'money_type': '币种',
        'fee': '费用',
        'with_tracking_no': '相关运单号',
        'trigger_event_name': '触发事件名称',
        'between_30_days': '最多可以选择跨度30天',

        'synchronous_now': '立即同步',
        'api_trackings_108': '停用店铺无法导入数据',
        'shop_sync_108': '停用店铺无法同步数据',

        'page_api_title': 'API配置',
        'add_api': '新增API',
        'edit_api': '编辑API',
        'api_nick_name': 'API别名',
        'connect_special_api': '连接专用API',
        'URL': 'URL',
        'language': '语言',
        'front_btn': '上一步',

        'api_save_105': 'API别名已存在',
        'api_save_106': 'API配置已存在',
        'enable_url': '有效URL',
        'disable_url': '无效URL',
        'invalid_query': '无效查询',
        'subscribeBalance_0': '您的订阅额度已用完，请联系客服进行充值。',
        'insufficient_subscribeBalance': '额度不足',

        "api_trackings_101": "至少输入一个用于查询的运单号",
        "api_trackings_102": "查询的运单号太多",
        "api_trackings_108_2": "没有可用的额度",
        'wait_query': '等待查询',
        'money': '元',

        'api_event_info_delete_105': '该事件下有相关匹配规则，无法删除',
        'api_event_info_delete_108': '该事件已被维护了通知任务，无法删除',
        'menu_app': "功能",
        'menu_more': "更多",

        "cancel_sync": "取消同步",
        "sync_status_tooltip": "您可以在历史关注运单中查看追踪进度!",
        "sync_status_step1": "成功同步数量",
        "sync_status_step2": "其中15条已存在并覆盖",
        "sync_status_title": "正在同步数据",
        "sync_status_title_pending": "同步数据已挂起",
        "sync_status_title_stopping": "同步数据停止中",
        "sync_status_title_stopped": "同步数据已停止",
        "sync_status_title_finished": "同步数据已完成",
        "sync_status_ok": "我知道了",
        "sync_cancel_title": "已同步运单不会取消,未同步的运单将不再进行同步操作,确认要取消同步吗?",
        "default_shop": "默认店铺",
        "shop": "店铺",
        "history_more_infomation": "更多信息",
        "history_more_infomation_open": "展开",
        "history_more_infomation_close": "收起",
        "carrier_website": "运输商网站",
        'range_user_bi_analysis_page_title': '分段统计',
        "data_analysis": "数据分析",
        "about_us": "关于我们",
        "about_us_des": "AI TRACK创立于2018年，为USPS、DHL、UPS、FedEx等全球超过500多家知名跨境电商和快递公司提供一站式精准的物流轨迹追踪服务。",
        "about_us_des2": "作为电商和跨境物流产业不可或缺的包裹追踪系统，AI TRACK每日追踪超50万个包裹，注册用户超80,000，在众多线上卖家和顾客间形成良好的口碑，并迅速成为了世界领先的第三方物流轨迹追踪平台。",
        "our_mission": "我们的使命",
        "our_mission_des": "我们竭力打造最优质的售后服务，以提升消费者的购物体验。售后服务决定了顾客的回头率，因此实现客户的需求是我们的主要宗旨。我们为用户提供他们想要的服务，以助力每一家电商相关企业的发展。",
        "about_us_title": "全球运单一站式追踪平台",
        // /2022-11-22
        "range_start_status": "起始状态",
        "range_end_status": "结束状态",

        // /2022-11-25
        "online_api": "在线API",
        "offline_api": "离线API",
        "api_type": "API类型",
        "zy_online_api": "专用在线API",
        "zy_offline_api": "专用离线API",
        "path": "路径",
        "trajectory_time": "轨迹时间",
        "trajectory_desc": "轨迹信息",

        // /2022-11-29
        "api_login_name": "登录名",
        "api_login_pwd": "登录密码",
        "api_ftp_type": "协议类型",
        "tracking": "追踪",

        "buy_subscribe_balance": "购买额度",
        "subscribe_shop_title": "套餐购买",
        "subscribe_shop_balance": "剩余余额",
        "subscribe_shop_times": "剩余订阅额度",
        "subscribe_shop_buy": "余额购买",
        "subscribe_shop_desc": "购买后, 所有订阅额度有效期将更新至最晚有效期",
        "subscribe_shop_row_subscribe": "订阅额度",
        "subscribe_shop_row_item": "单",
        "subscribe_shop_row_pic": "每单",
        "subscribe_shop_row_start_date": "自购买日起",
        "1month": "1个月",
        "3month": "3个月",
        "6month": "半年",
        "1year": "1年",
        "2year": "2年",
        "5year": "5年",
        "balance_order_productName": "商品名称",
        "balance_order_productPrice": "价格",
        "balance_order_productCount": "商品数量",
        "balance_order_productTotal": "商品总价",
        "balance_order_productPayType": "支付方式",
        "balance_order_balancePay": "余额支付",
        "balance_order_balance": "剩余余额",
        "balance_order_needPay": "应付",
        "balance_order_pay": "支付",
        "user_subscribe_payment_103": "余额不足请充值",
        "user_subscribe_payment_104": "支付失败请重试",
        "user_subscribe_payment_success": "套餐购买成功",
        "cost_by_subscribe": "订阅额度消费",
        "buy_subscribe": "套餐购买",
        "valid": "有效期",
        "buy_confirm": "确认购买该商品?",
        "sms_country_price_add":"添加发送短信国家",
        "sms_country_price_tooltip":"注：只发送给已选的国家短信,未选择的国家将不发送短信通知",
        "choose_send_country":"选择发送短信国家",
        "yuan_piece":"元/条",
        "exist_send_country":"发送短信国家已存在",
        "please_choose_send_country":"请添加发送短信国家",
        "plan_title":"额度套餐",
        "sms_description_part1_1":"国际包裹查询",
        "sms_description_part1_2":"高质量、稳定、及时",
        "sms_description_part1_3":"可通过调用接口，获取全球 500+ 的运输商轨迹数据， 让您的运营更高效。",
        "sms_description_part1_4":"注册-免费使用",
        "sms_description_part2_1":"您是否在寻找这些问题的解决方案？",
        "sms_description_part2_2":"有大批量的运单要查询，并持续跟踪",
        "sms_description_part2_3":"希望在您的后台集成查询功能",
        "sms_description_part2_4":"物流渠道多，跟踪成本太高",
        "sms_description_part3_1":"AITRACK可处理海量级包裹 跟踪",
        "sms_description_part3_2":"通过API订阅海量级运单, 包裹状态将持续自动化更新, 直到包裹被签收。",
        "sms_description_part4_1":"您将实现更精细的数据运营:<br/>预测、监控和监督",
        "sms_description_part4_2":"提供高品质的查询数据",
        "sms_description_part4_3":"呈现8大物流状态，详细的包裹状态让您更容易判断物流进展和了解异常情况的具体原因。",
        "sms_description_part4_4":"支持全球主流运输商",
        "sms_description_part4_5":"AITRACK 目前已支持 500+ 全球主流运输商，并且每周都在增加新的运输商对接，如您有其他需要支持的，请随时联系我们。",
        "sms_description_part4_6":"实现自动化物流跟踪",
        "sms_description_part4_7":"自动识别超过80%的运输商，一单到底，持续追踪，定时到物流商官网获取最新物流事件，直到包裹签收成功。",
        "sms_description_part5_1":"了解报价&如何对接",
        "sms_description_part5_2":"所有套餐有效期长达 6 个月。",
        "sms_description_part5_3":"订阅额度使用方式",
        "sms_description_part5_4_1":"· 页面查询及接口订阅均扣订阅额度;",
        "sms_description_part5_4_2":"· 同一个运单（相同运输商，相同查询语言）多次查询不会重复计费。但如果运单删除后重新查询会重新扣额度; ",
        "sms_description_part5_4_3":"· 同一个运单重复调用API不会重复计费;",
        "sms_description_part5_4_4":"· 新用户注册都将免费赠送5,000个订阅额度;",
        "sms_description_part5_4_5":"· 超有效期未用完的额度不会被清除，充值后所有的额度有效期变更为最晚的截止日期。",
        "free_gift_subscribe":"新用户赠送",
        "free":'免费',
        "shopify_oauth": "去授权",
        "shopify_reset_oauth": "重新授权",
        "shopify_success": "授权成功",
        "oauth_status": "授权状态",
        "oauth_status_Unauthorized": "待授权",
        "oauth_status_Authorized": "已授权",
        "oauth_status_AuthExpired": "授权已过期",

            //2022-11-04
        "shopify_title": "自动为您创建关联Shopify的账户",
        "shopify_description": "我们已将您的账户密码发送至邮箱 #, 您可以登录后台更换密码",

        "page_user_customer_title": "客户基础配置",
        "customer_id": "客户Id",
        //2023-02-06
        "table_header_sort":"排序",
        "matchRule_sort_title":"排序(正整数)",
        "matchRule_sort_tooltip":"排序的作用：如果一条轨迹匹配到多条公式，则按照公式的排序进行匹配。<br/>排序维护的内容：正整数 (如:1,2,3,5,88等)。<br/>排序的优先级：数值越小，匹配优先级越高。排序不填则默认非兜底的最小优先级。兜底公式优先级低于非兜底公式。",
        "test":"测试",
        "match_success":"匹配成功",
        "match_fail":"匹配失败",
        "match_track":"轨迹测试匹配",
        "match_trackingNo":"运单测试匹配",
        "close":"关闭",
        "tracking_description":"追踪描述",
        "is_self":"是否自建",
        "is_bottom_formula":"是否兜底公式",
        "yes":"是",
        "no":"否",
        "place":"地点",
        "other_match_reg":"其他可匹配的正则表达式",
        "no_balance":"您的<b>订阅额度不足</b>，请点击购买额度进行购买。",
        "expiry_balance":"您的可用订阅额度不足（<b>订阅额度已失效</b>），购买额度后额度有效期延长。",

        'range_otd_tt_analysis_title': '已完成分析',
        'range_otd_utt_analysis_title': '未完成分析',
        'range_tt_days_title': '完成天数阶梯分段',
        'range_ntt_days_title': '未完成天数阶梯分段',
        'range_proper_investment_title': '完成情况',
        'range_delivery_success_rate_title': '完成率',
        'range_proportion_of_investment_days_title': '完成天数占比',
        'range_details_of_completed_investment_title': '已完成明细',
        'range_failure_rate_title': '未完成率',
        'range_details_of_incomplete_investment_title': '未完成明细',
        'range_unsuccessful_delivery_orders_title': '未完成运单数',
        'range_unsuccessful_bid_rate_title': '未完成率',
        'range_successful_delivery_orders_title': '已完成运单数',
        'range_successful_bid_rate_title': '完成率',
        'range_shipped_orders_title': '已完成运单数',
        'range_unshipped_orders_title': '未完成运单数',
        'range_export_delivered': '导出已完成运单',
        'range_export_un_delivered': '导出未完成运单',
        'range_select_tt_days_title': '请选择完成天数阶梯分段',
        'range_select_ntt_days_title': '请选择未完成天数阶梯分段',
        'range_delivered_votes_title': '已完成票数',
        'range_un_delivered_votes_title': '未完成票数',
        'range_total_number_of_waybills_including_undelivered_waybills':
            '总运单数 (含未完成运单)',
        'range_export_otd_title': '导出延迟及未完成运单',
        'range_standard_title': '完成',
        'range_un_standard_title': '未完成',
        'range_proportion_of_up_to_standard_title': '完成占比',
        'range_time_for_reaching_the_standard_title': '完成用时',
        'event_status': '事件状态',
        "event_status_same": "事件状态不能相同",
        'bind_title': '去绑定',
        'google_bind_success': '绑定成功',
        'google_unbind_success': '解绑成功',
        'google_unbind_tip':'如果你解绑这个平台，将无法快速直接登录，下次需要通过账号密码登录，是否需要继续操作？',
        'google_bind_exists':'该Google账户已有绑定账号,请先解绑后再重新绑定该账号。'

    }
}
