import webConfig from '@/config/wenConfig';

export default {
    getBaseUrl() {
        let apiUrl;

        switch (process.env.NODE_ENV) {
            case "production":
                // 正式环境
                apiUrl = "https://api.aitrack.com/portal";
                // apiUrl = "http://172.30.10.12:7060/portal";
                break;
            case "development":
                // 本地环境
                apiUrl = "http://localhost:8888/portal";
                break;
        }

        return apiUrl;
    },

    //网站地址
    getBaseWebsitUrl() {
        let webUrl;
        
        switch (process.env.NODE_ENV) {
            case "production":
                // 正式环境
                webUrl = "https://aitrack.com/";
                // webUrl = "http://172.30.10.12:10040/";
                break;
            case "development":
                // 本地环境
                webUrl = "http://localhost:8889/";
                break;
        }

        return webUrl;
    },

    getWebsitUrl() {
        let webUrl = this.getBaseWebsitUrl();
        return webUrl +  webConfig.getCurrentLang() + '/';
    }
}

