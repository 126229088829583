import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-1a548e75"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  style: {
    "overflow-x": "auto"
  },
  class: "mainDivWidth"
};
import { provide, nextTick, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import AtLoading from "@/components/AtLoading.vue";
import { isPhone } from '@/utils/tool';
import { Spin } from "ant-design-vue";
export default {
  __name: 'App',

  setup(__props) {
    let uiData = reactive({
      isRouterAlive: true
    });
    let pageData = reactive({
      tmpPhone: isPhone()
    });
    const router = useRouter(); //必须写在外面才行，如果在函数中定义调用，就会报错。

    const reload = () => {
      uiData.isRouterAlive = false;
      nextTick(() => {
        uiData.isRouterAlive = true;
      });
    };

    Spin.setDefaultIndicator({
      indicator: h(AtLoading, {
        style: {},
        spin: true
      })
    });
    onMounted(() => {
      provide('reload', reload);

      window.onresize = function () {
        if (pageData.tmpPhone != isPhone()) {
          pageData.tmpPhone = isPhone();
          reload();
        }
      };
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_unref(uiData).isRouterAlive ? (_openBlock(), _createBlock(_component_router_view, {
        key: _unref(router).currentRoute.value.fullPath
      })) : _createCommentVNode("", true)]);
    };
  }

};