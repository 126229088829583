import {message} from 'ant-design-vue';

export const warningMessage = (description) => {
    message.open({
        content: () => description,
        class: 'at-message-warning'
    });
}

export const errorMsg = (description) => {
    message.error(description);
}

export const successMessage = async (description) => {
    message.open({
        content: () => description,
        class: 'at-message-success'
    });
}
